import { defineStore } from 'pinia';
import { GuildsStoreStateType } from '@/js/quests/dashboard/pages/guilds/types/guilds';
import { getGuilds, getSuggestedGuilds } from '@/js/quests/dashboard/pages/guilds/api/guilds';
import {
    CUSTOM_PAGINATION_PER_PAGE,
    DEFAULT_PAGINATION_PER_PAGE,
    ALTERNATE_PAGINATION_PER_PAGE,
    SUGGESTIONS_PAGINATION_PER_PAGE,
    RECOMMENDED_SUGGESTIONS_PAGINATION_PER_PAGE,
} from '@/js/quests/dashboard/pages/guilds/constants/pagination';
import { GuildTypeOptionsEnum } from '@/js/quests/dashboard/pages/guilds/types/filters';
import { DEFAULT_SORT } from '@/js/quests/dashboard/pages/guilds/constants/filters';

export const useGuildsStore = defineStore({
    id: 'guilds',
    state: (): GuildsStoreStateType => ({
        allGuilds: [],
        followedGuilds: [],
        ownedGuilds: [],
        isLoadingAllGuilds: false,
        isLoadingFollowedGuilds: false,
        isLoadingOwnedGuilds: false,
        isFetchAllGuildsCompleted: false,
        isFetchFollowedGuildsCompleted: false,
        isFetchOwnedGuildsCompleted: false,
        allGuildsCurrentPage: 1,
        followedGuildsCurrentPage: 1,
        isAllGuildsLastPage: false,
        isFollowGuildsLastPage: false,
        filters: {
            search: '',
            sort: DEFAULT_SORT,
            guildType: GuildTypeOptionsEnum.ALL,
        },
        isFollowedGuildsVisible: true,
        unfollowedGuilds: {
            list: [],
            isLoading: false,
            isFetchCompleted: false,
        },
        suggestedGuilds: {
            selected: ['bitdegree'],
            list: [],
            isLoading: false,
            isFetchCompleted: false,
            isLastPage: false,
            currentPage: 1,
            search: '',
        },
        recommendedGuilds: {
            list: [],
            isLoading: false,
            isFetchCompleted: false,
        },
    }),
    getters: {
        followedSuggestedGuildsNumber(): number {
            return this.suggestedGuilds.selected.length;
        },
    },
    actions: {
        toggleGuildCardFollow(slug: string, status: boolean) {
            const allGuildCollections = [this.allGuilds, this.followedGuilds, this.ownedGuilds, this.unfollowedGuilds.list, this.suggestedGuilds.list, this.recommendedGuilds.list];

            allGuildCollections.forEach((collection) => {
                const card = collection.find((card) => card.slug === slug);

                if (card) {
                    card.is_followed = status;
                }
            });

            const followedCardIndex = this.followedGuilds.findIndex((card) => card.slug === slug);

            if (status && followedCardIndex === -1) {
                const cardToAdd = this.allGuilds.find((card) => card.slug === slug) || this.ownedGuilds.find((card) => card.slug === slug);

                if (cardToAdd) {
                    this.followedGuilds = [...this.followedGuilds, cardToAdd];
                }
            } else if (!status && followedCardIndex !== -1) {
                const newArray = [...this.followedGuilds];
                newArray.splice(followedCardIndex, 1);
                this.followedGuilds = newArray;
            }
        },
        resetAllGuildsFilterType() {
            this.filters.guildType = GuildTypeOptionsEnum.ALL;
        },
        resetAllGuildsCurrentPage() {
            this.allGuildsCurrentPage = 1;
        },
        increaseAllGuildsCurrentPage() {
            this.allGuildsCurrentPage += 1;
        },
        hasActiveFilters() {
            return this.filters.search || this.filters.guildType !== GuildTypeOptionsEnum.ALL;
        },
        checkSelectedSuggestions() {
            this.suggestedGuilds.list.forEach((item) => {
                if (this.suggestedGuilds.selected.includes(item.slug)) {
                    item.is_followed = true;
                }
            });
        },
        toggleSuggestedGuildFollow(slug: string, status: boolean) {
            const selectedGuild = this.suggestedGuilds.list.find((guild) => guild.slug === slug);

            if (selectedGuild) {
                selectedGuild.is_followed = status;
            }

            const selectedIndex = this.suggestedGuilds.selected.findIndex((slugName) => slugName === slug);

            if (status && selectedIndex === -1) {
                    this.suggestedGuilds.selected = [...this.suggestedGuilds.selected, slug];
            } else if (!status && selectedIndex !== -1) {
                    const newArray = [...this.suggestedGuilds.selected];
                    newArray.splice(selectedIndex, 1);
                    this.suggestedGuilds.selected = newArray;
            }
        },
        increaseSuggestedGuildsCurrentPage() {
            this.suggestedGuilds.currentPage += 1;
        },
        resetSuggestedGuildsCurrentPage() {
            this.suggestedGuilds.currentPage = 1;
        },
        async fetchGuilds(isTypeNotFollowed: boolean, isDefaultPagination: boolean) {
            try {
                this.isLoadingAllGuilds = true;
                this.isFollowedGuildsVisible = !this.hasActiveFilters();

                const guildType = (isTypeNotFollowed && !this.hasActiveFilters()) ? GuildTypeOptionsEnum.NOT_FOLLOWED : this.filters.guildType;
                const perPage = isDefaultPagination ? DEFAULT_PAGINATION_PER_PAGE : ALTERNATE_PAGINATION_PER_PAGE;

                const response = await getGuilds(
                    { search: this.filters.search, sort: this.filters.sort, guildType },
                    { page: this.allGuildsCurrentPage, perPage },
                );

                if (this.allGuildsCurrentPage === 1) {
                    this.allGuilds = response.data;
                } else {
                    this.allGuilds = [...this.allGuilds, ...response.data];
                }

                this.isAllGuildsLastPage = this.allGuildsCurrentPage === response.meta.last_page;
            } finally {
                this.isLoadingAllGuilds = false;
                this.isFetchAllGuildsCompleted = true;
            }
        },
        async fetchFollowedGuilds() {
            try {
                this.isLoadingFollowedGuilds = true;

                const response = await getGuilds(
                    { search: '', sort: this.filters.sort, guildType: GuildTypeOptionsEnum.FOLLOWED },
                    { page: this.followedGuildsCurrentPage, perPage: CUSTOM_PAGINATION_PER_PAGE },
                );

                this.followedGuilds = response.data;
                this.isFollowGuildsLastPage = this.followedGuildsCurrentPage === response.meta.last_page;
            } finally {
                this.isLoadingFollowedGuilds = false;
                this.isFetchFollowedGuildsCompleted = true;
            }
        },
        async fetchUnfollowedGuilds(isTypeNotFollowed: boolean) {
            try {
                this.unfollowedGuilds.isLoading = true;

                const guildType = isTypeNotFollowed ? GuildTypeOptionsEnum.NOT_FOLLOWED : GuildTypeOptionsEnum.ALL;

                const response = await getGuilds(
                    { search: '', sort: DEFAULT_SORT, guildType },
                    { page: 1, perPage: RECOMMENDED_SUGGESTIONS_PAGINATION_PER_PAGE },
                );

                this.unfollowedGuilds.list = response.data;
            } finally {
                this.unfollowedGuilds.isLoading = false;
                this.unfollowedGuilds.isFetchCompleted = true;
            }
        },
        async fetchOwnedGuilds() {
            try {
                this.isLoadingOwnedGuilds = true;

                const response = await getGuilds(
                    { search: '', sort: DEFAULT_SORT, guildType: GuildTypeOptionsEnum.OWNED },
                    { page: 1, perPage: CUSTOM_PAGINATION_PER_PAGE },
                );
                this.ownedGuilds = response.data;
            } finally {
                this.isLoadingOwnedGuilds = false;
                this.isFetchOwnedGuildsCompleted = true;
            }
        },
        async fetchSuggestedGuilds() {
            try {
                this.suggestedGuilds.isLoading = true;

                const response = await getSuggestedGuilds(this.suggestedGuilds.search, { page: this.suggestedGuilds.currentPage, perPage: SUGGESTIONS_PAGINATION_PER_PAGE });

                if (this.suggestedGuilds.currentPage === 1) {
                    this.suggestedGuilds.list = response.data;
                } else {
                    this.suggestedGuilds.list = [...this.suggestedGuilds.list, ...response.data];
                }

                this.checkSelectedSuggestions();

                this.suggestedGuilds.isLastPage = this.suggestedGuilds.currentPage === response.meta.last_page;
            } finally {
                this.suggestedGuilds.isLoading = false;
                this.suggestedGuilds.isFetchCompleted = true;
            }
        },
        async fetchRecommendedGuilds(isTypeNotFollowed: boolean) {
            try {
                this.recommendedGuilds.isLoading = true;

                const guildType = isTypeNotFollowed ? GuildTypeOptionsEnum.NOT_FOLLOWED : GuildTypeOptionsEnum.ALL;

                const response = await getGuilds(
                    { search: '', sort: DEFAULT_SORT, guildType },
                    { page: 1, perPage: RECOMMENDED_SUGGESTIONS_PAGINATION_PER_PAGE },
                );

                this.recommendedGuilds.list = response.data;
            } finally {
                this.recommendedGuilds.isLoading = false;
                this.recommendedGuilds.isFetchCompleted = true;
            }
        },
    },
});
